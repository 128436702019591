import React from 'react';
import discoverImage from '../assets/68.png';
import deliveryImage from '../assets/69.png';
import videoTourImage from '../assets/70.png';

const ExperienceZulu = () => {
  const features = [
    {
      id: 1,
      title: 'WINDOW SHOP',
      subtitle: 'Explore as much as you want, with no commitment to buy',
      
      buttonClass: 'bg-white/10',
      bgImage: deliveryImage,
    },
    {
      id: 2,
      title: 'VIDEO TOUR',
      subtitle: 'Instantly tour malls & outlets to find what you are looking for',
      
      buttonClass: 'bg-white/10',
      bgImage: discoverImage,
    },
    {
      id: 3,
      title: 'DELIVERY & RETURNS',
      subtitle: 'Get it within 100 mins, return or exchange as per outlet policy',
      
      buttonClass: 'bg-white/10',
      bgImage: videoTourImage,
    },
  ];

  return (
    <div className="bg-black py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4">
          Experience Zulu
        </h1>
        <p className="text-center text-lg md:text-xl text-gray-300 font-light mb-10">
          Where Fashion Meets Your Lifestyle, Instantly
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="relative"
            >
              <div className="relative rounded-lg overflow-hidden bg-white/5 border border-white/10">
                <img
                  src={feature.bgImage}
                  alt={feature.title}
                  className="w-full h-[320px] md:h-[380px] object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
                  <div className="absolute top-4 right-4">
                    <div className={`${feature.buttonClass} backdrop-blur-sm text-white px-4 py-2 rounded-lg text-sm font-medium`}>
                      {feature.buttonText}
                    </div>
                  </div>
                  <div className="absolute inset-x-0 bottom-0 p-6">
                    <h2 className="text-xl md:text-2xl font-bold mb-2 text-white">
                      {feature.title}
                    </h2>
                    <p className="text-sm md:text-base text-gray-300">
                      {feature.subtitle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExperienceZulu;
