import React, { useState, forwardRef } from 'react';
import { ChevronRight, Instagram } from 'lucide-react';
import chatbotIcon from '../assets/5.svg';
import videoIcon from '../assets/4.svg';
import { useNavigate } from 'react-router-dom';
import shopNowGif from '../assets/SHOP NOW (23).png';
import bgImage from '../assets/SHOP NOW (22).png';
import zuluphone from '../assets/CopyZuluphone.png';
import playbutton from '../assets/4.png';
import appintro from '../assets/zulu app.mp4';

// import img14 from '../assets/Hero/14.png';
// import img15 from '../assets/Hero/15.png';
// import img16 from '../assets/Hero/16.png';
// import img17 from '../assets/Hero/17.png';
// import img18 from '../assets/Hero/18.png';
// import img19 from '../assets/Hero/19.png';
// import img20 from '../assets/Hero/20.png';
// import img21 from '../assets/Hero/21.png';
// import img22 from '../assets/Hero/22.png';
// import img23 from '../assets/Hero/23.png';
// import img24 from '../assets/Hero/24.png';
// import img25 from '../assets/Hero/25.png';
// import img26 from '../assets/Hero/26.png';
// import img27 from '../assets/Hero/27.png';
// import img28 from '../assets/Hero/28.png';
// import img29 from '../assets/Hero/29.png';
// import img30 from '../assets/Hero/30.png';
// import img31 from '../assets/Hero/31.png';
// import img32 from '../assets/Hero/32.png';
// import img33 from '../assets/Hero/33.png';
// import img34 from '../assets/Hero/34.png';
// import img35 from '../assets/Hero/35.png';

// // Create an array of all images
// const floatingImages = [
//   img14, img15, img16, img17, img18, img19, img20, img21, img22, img23,
//   img24, img25, img26, img27, img28, img29, img30, img31, img32, img33,
//   img34, img35
// ];

const HeroSection = forwardRef((props, ref) => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length <= 10) {
      setMobileNumber(value);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const getISTTimestamp = () => {
    const now = new Date();
    const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
    return istTime.toISOString()
      .replace('Z', '+05:30')
      .slice(0, 16);
  };

  const handleMobileSubmit = async () => {
    const mobileRegex = /^\d{10}$/;

    if (!mobileRegex.test(mobileNumber.trim())) {
      alert('Please enter a valid 10-digit mobile number');
      return;
    }

    setIsSubmitting(true);

    try {
      const timestamp = getISTTimestamp();
      const formData = new URLSearchParams();
      formData.append('mobileNumber', mobileNumber.trim());
      formData.append('timestamp', timestamp);

      const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });

      console.log('Request sent successfully');
      console.log('Submitted Data:', {
        mobileNumber: mobileNumber.trim(),
        timestamp: timestamp
      });
      
      setShowNameInput(true);
      
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting your mobile number. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNameSubmit = async () => {
    if (!name.trim()) {
      alert('Please enter your name');
      return;
    }

    setIsSubmitting(true);

    try {
      const timestamp = getISTTimestamp();
      const formData = new URLSearchParams();
      formData.append('name', name.trim());
      formData.append('mobileNumber', mobileNumber.trim());
      formData.append('timestamp', timestamp);
      formData.append('Type', 'Customer');

      const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });

      console.log('Request sent successfully');
      console.log('Submitted Data:', {
        name: name.trim(),
        mobileNumber: mobileNumber.trim(),
        timestamp: timestamp
      });
      
      setShowNameInput(false);
      setShowThankYou(true);
      
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting your name. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div 
      ref={ref} 
      className="relative min-h-screen w-screen flex items-center justify-center overflow-hidden"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(20,0,0,0.94), rgba(10,0,0,0.98)), url(${bgImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: '100vh',
        width: '100vw',
      }}
    >
      {/* Dark red ambient gradient */}
      <div className="absolute inset-0 bg-gradient-to-tr from-black via-transparent to-black" />
      
      {/* Floating Images Container */}
      {/* <div className="absolute inset-x-0 top-[20vh] h-[50vh] pointer-events-none overflow-hidden">
        {floatingImages.map((img, index) => {
          // Determine which row this image belongs to (0, 1, or 2)
          const row = Math.floor(index / Math.ceil(floatingImages.length / 3));
          
          // Calculate vertical position based on row
          const rowPosition = (row * 33.33) + 5; // 33.33% per row, 5% padding
          
          return (
            <div
              key={index}
              className={`absolute animate-float-${index % 5} transition-all duration-1000
                        transform hover:scale-110`}
              style={{
                left: `${(index % Math.ceil(floatingImages.length / 3)) * (100 / Math.ceil(floatingImages.length / 3))}%`, // Evenly space horizontally within row
                top: `${rowPosition}%`, // Position based on row
                width: `${Math.max(100, Math.random() * 240)}px`,
                animation: `float-${index % 5} ${5 + Math.random() * 5}s infinite`,
                animationDelay: `${Math.random() * 5}s`,
                margin: '20px',
                zIndex: Math.floor(Math.random() * 10),
                transform: 'translate(-50%, -50%)' // Center the image on its position point
              }}
            >
              <img
                src={img}
                alt={`Floating ${index + 14}`}
                className="w-full h-full object-contain opacity-60 hover:opacity-90 transition-opacity"
              />
            </div>
          );
        })}
      </div> */}
      
      {/* Main Container with mobile-first approach */}
      <div className="relative w-full h-screen max-w-[1440px] mx-auto">
        {/* Mobile Layout (default) */}
        <div className="flex flex-col items-center justify-center h-full
                        space-y-6 px-4
                        md:hidden">
          
          {/* Text Container - Moved to top */}
          <div className="space-y-1.5 text-center translate-y-[15%]">
            <h1 className="text-3xl sm:text-[2.8rem]
                           font-black tracking-wider 
                           text-transparent bg-clip-text 
                           bg-gradient-to-r from-white/90 via-red-100/80 to-white/90">
              Shop from Malls
            </h1>
            <h2 className="text-2xl sm:text-[2.3rem]
                           font-black tracking-wide animate-pulse 
                           text-red-800">
              Get it in 100 mins
            </h2>
          </div>

          {/* DO THE ZULU text - Moved after main text */}
          <h2 onClick={() => setShowVideo(true)}
              className="text-sm sm:text-2xl
                         font-black tracking-wide text-white/90
                         font-Poppins text-center animate-pulse
                         cursor-pointer hover:text-white transition-colors duration-300">
            Click here to know more
          </h2>
          
          {/* Zuluphone Container - Moved after text */}
          <div className="relative w-[72%] max-w-[315px] h-[40vh]">
            {/* Play Button */}
            <div 
              onClick={() => setShowVideo(true)}
              className="absolute z-20 
                         left-1/2 top-1/2 
                         -translate-x-1/2 -translate-y-1/2
                         w-14 h-14
                         cursor-pointer">
              {/* <img 
                src={playbutton} 
                alt="Play Video"
                className="w-full h-full object-contain
                           opacity-90 hover:opacity-100
                           transform transition-all duration-300
                           hover:scale-110"
              /> */}
            </div>
            
            {/* Zuluphone Image */}
            <img 
              src={zuluphone} 
              alt="Zulu Phone"
              className="w-full h-full object-contain
                         opacity-90 hover:opacity-100
                         transition-all duration-300"
            />

            {/* Click to Play Text - Mobile */}
            {/* <p className="absolute -bottom-6 left-1/2 transform -translate-x-1/2
                          text-[10px] text-white/70 whitespace-nowrap
                          animate-pulse">
              Click Play Button to know more
            </p> */}
          </div>
          
          {/* Boxes Container - Horizontal Row */}
          <div className="flex flex-row justify-center 
                          gap-3 sm:gap-5 
                          w-full px-4">
            
            {/* Video Tour Box */}
            <div onClick={() => setShowDownloadPopup(true)}
                 className="group relative 
                            w-[128px] h-[82px] sm:w-[117px] sm:h-[117px] translate-x-[-5%]
                            cursor-pointer">
              <div className="relative h-full
                              bg-black/30 backdrop-blur-md 
                              border border-white/30 rounded-lg 
                              p-3 sm:p-5
                              group-hover:bg-black/40 
                              group-hover:border-red-800/50
                              transition-all duration-300">
                <div className="flex flex-col items-center justify-center h-full gap-2.5">
                  <div className="w-8 h-8 sm:w-9 sm:h-9 translate-y-[5%]
                                 flex items-center justify-center 
                                 bg-white/[0.02] rounded-lg">
                    <img src={videoIcon} 
                         alt="Video Tour"
                         className="w-4.5 h-4.5 sm:w-6 sm:h-6
                                   object-contain opacity-90
                                   group-hover:opacity-100" />
                  </div>
                  <div className="text-center translate-y-[-12%]">
                    <span className="block text-xs sm:text-sm 
                                    font-semibold text-white/90">VIDEO TOUR</span>
                    <span className="block text-[12px]
                                    text-white/50">Malls & Outlets</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Ask Zulu Box */}
            <div onClick={() => navigate('/ask-zulu')}
                 className="group relative 
                            w-[128px] h-[82px] sm:w-[117px] sm:h-[117px] translate-x-[5%]
                            cursor-pointer">
              <div className="relative h-full
                              bg-black/30 backdrop-blur-md 
                              border border-white/30 rounded-lg 
                              p-3 sm:p-5
                              group-hover:bg-black/40 
                              group-hover:border-red-800/50
                              transition-all duration-300">
                <div className="flex flex-col items-center justify-center h-full gap-2.5">
                  <div className="w-8 h-8 sm:w-9 sm:h-9 translate-y-[5%]
                                 flex items-center justify-center 
                                 bg-white/[0.02] rounded-lg">
                    <img src={chatbotIcon} 
                         alt="Ask Zulu"
                         className="w-4.5 h-4.5 sm:w-6 sm:h-6
                                   object-contain opacity-90
                                   group-hover:opacity-100" />
                  </div>
                  <div className="text-center translate-y-[-12%]">
                    <span className="block text-xs sm:text-sm
                                    font-semibold text-white/90">ASK ZULU</span>
                    <span className="block text-[12px]
                                    text-white/50">Fashion Chatbot</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Desktop Layout - Hide on mobile */}
        <div className="hidden md:flex justify-between items-center h-full">
          {/* Left Section - Text and Boxes */}
          <div className="flex flex-col items-center 
                          space-y-8 sm:space-y-12 lg:space-y-16 2xl:space-y-24
                          pl-6 sm:pl-12 lg:pl-20 2xl:pl-40
                          w-1/2">
            
            {/* FASHION CONCIERGE Text */}
            <div className="space-y-2 text-left sm:space-y-4 2xl:space-y-8 w-full xl:translate-x-[20%] 2xl:translate-x-[5%] xl:translate-y-[5%] 2xl:translate-y-[30%]">
              <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-6xl 2xl:text-6xl
                             font-black tracking-wider 
                             text-transparent bg-clip-text 
                             bg-gradient-to-r from-white/90 via-red-100/80 to-white/90 2xl:translate-y-[40%] lg:translate-y-[40%]">
                Shop from Malls
              </h1>
              <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-5xl 2xl:text-[3.5rem] 2xl:translate-y-[40%]
                             font-black tracking-wide animate-pulse
                             text-red-800 lg:translate-y-[80%]">
                Get it in 100 mins
              </h2>
            </div>

            {/* DO THE ZULU text with pulse animation */}
            <h2 onClick={() => setShowVideo(true)}
                className="text-2xl sm:text-3xl md:text-4xl lg:text-2xl 2xl:text-3xl
                          tracking-wide text-white/90
                          font-Poppins text-left w-full
                          animate-pulse xl:translate-x-[21%] 2xl:translate-x-[6%] xl:translate-y-[20%] 2xl:translate-y-[60%]
                          cursor-pointer hover:text-white transition-colors duration-300">
              Click here to know more
            </h2>

            {/* Boxes Container */}
            <div className="flex flex-col sm:flex-row 
                            items-start 
                            gap-6 sm:gap-8 md:gap-12 lg:gap-16 2xl:gap-24">
              
              {/* Video Tour Box */}
              <div onClick={() => setShowDownloadPopup(true)}
                   className="group relative 
                              w-[115px] h-[115px] 
                              sm:w-[117px] sm:h-[117px] 
                              md:w-[288px] md:h-[108px] 
                              lg:w-[270px] lg:h-[126px] lg:translate-x-[43%] lg:translate-y-[10%]
                              2xl:w-[308px] 2xl:h-[126px] 2xl:translate-x-[36%] 2xl:translate-y-[10%]
                              cursor-pointer
                              transition-all duration-300">
                <div className="relative h-full
                                bg-black/30 backdrop-blur-md 
                                border border-white/30 rounded-2xl 
                                p-4 sm:p-6 md:p-8 lg:p-10 2xl:p-12
                                group-hover:bg-black/40 
                                group-hover:border-red-800/50
                                transition-all duration-300">
                  <div className="flex items-center justify-start gap-4 h-full">
                    <div className="w-12 h-12 sm:w-16 sm:h-16 
                                   md:w-20 md:h-20 lg:w-12 lg:h-15
                                   2xl:w-25 2xl:h-28
                                   flex items-center justify-center 
                                   bg-white/[0.02] rounded-lg">
                      <img src={videoIcon} 
                           alt="Video Tour"
                           className="w-8 h-8 sm:w-10 sm:h-10 
                                     md:w-12 md:h-12 lg:w-14 lg:h-14
                                     2xl:w-16 2xl:h-16
                                     object-contain opacity-90
                                     group-hover:opacity-100
                                     transition-all duration-300" />
                    </div>
                    <div className="text-left">
                      <span className="block text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl
                                      font-semibold text-white/90">VIDEO TOUR</span>
                      <span className="block text-xs sm:text-sm md:text-base 2xl:text-lg
                                      text-white/50">Malls & Outlets</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Ask Zulu Box */}
              <div onClick={() => navigate('/ask-zulu')}
                   className="group relative 
                              w-[115px] h-[115px] 
                              sm:w-[117px] sm:h-[117px] 
                              md:w-[288px] md:h-[108px] 
                              xl:w-[270px] xl:h-[126px] xl:translate-x-[40%] xl:translate-y-[10%]
                              2xl:w-[308px] 2xl:h-[126px] 2xl:translate-x-[20%] 2xl:translate-y-[10%]
                              cursor-pointer
                              transition-all duration-300">
                <div className="relative h-full
                                bg-black/30 backdrop-blur-md 
                                border border-white/30 rounded-2xl 
                                p-4 sm:p-6 md:p-8 lg:p-10 2xl:p-12
                                group-hover:bg-black/40 
                                group-hover:border-red-800/50
                                transition-all duration-300">
                  <div className="flex items-center justify-start gap-4 h-full">
                    <div className="w-12 h-12 sm:w-16 sm:h-16 
                                   md:w-20 md:h-20 xl:w-10 xl:h-12 
                                   2xl:w-25 2xl:h-28
                                   flex items-center justify-center 
                                   bg-white/[0.02] rounded-lg">
                      <img src={chatbotIcon} 
                           alt="Ask Zulu"
                           className="w-8 h-8 sm:w-10 sm:h-10 
                                     md:w-12 md:h-12 lg:w-14 lg:h-14
                                     2xl:w-16 2xl:h-16
                                     object-contain opacity-90
                                     group-hover:opacity-100
                                     transition-all duration-300 " />
                    </div>
                    <div className="text-left">
                      <span className="block text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl
                                      font-semibold text-white/90">ASK ZULU</span>
                      <span className="block text-xs sm:text-sm md:text-base xl:text-sm 2xl:text-lg
                                      text-white/50">Fashion Chatbot</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          {/* Right Section - Zuluphone and Play Button */}
          <div className="relative w-1/2 h-full 
                        flex items-center 
                        justify-end md:justify-center
                        pr-6 sm:pr-12 xl:w-[300px] xl:h-[300px] 2xl:w-[325px] 2xl:h-[325px] 4xl:w-[375px] 4xl:h-[375px]
                     md:-translate-x-[10%]
                         
                        xl:-translate-x-[45%] 
                        2xl:-translate-x-[20%]
                        2xl:translate-y-[3%]
                        ">
            {/* Play Button - Adjusted for laptop screens */}
            <div 
              onClick={() => setShowVideo(true)}
              className="absolute z-50 
                         left-1/2 top-1/2 
                         -translate-x-1/2 -translate-y-1/2
                         translate-x-[20%] sm:translate-x-[25%]
                         md:translate-x-[-15%]    {/* Changed to -15% for laptop */}
                         lg:translate-x-[-28%]    {/* Maintained -15% for larger laptops */}
                         2xl:translate-x-[-70%]   {/* Maintained -15% for 2xl */}
                         w-12 h-12 sm:w-16 sm:h-16 
                         md:w-20 md:h-20 2xl:w-28 2xl:h-28
                         cursor-pointer">
              <img 
                // src={playbutton} 
                // alt="Play Video"
                // className="w-full h-full object-contain
                //            opacity-90 hover:opacity-100
                //            transform transition-all duration-300
                //            hover:scale-110"
              />
            </div>

            {/* Zuluphone */}
            <div className="relative
                            w-[clamp(200px,45vw,400px)] 2xl:w-[clamp(300px,45vw,500px)]
                            h-[clamp(400px,70vh,600px)] 2xl:h-[clamp(450px,70vh,650px)] lg:w-[clamp(200px,45vw,400px)] lg:h-[clamp(400px,70vh,500px)]">
              <img 
                src={zuluphone} 
                alt="Zulu Phone"
                className="w-full h-full object-contain
                           opacity-90 hover:opacity-100
                           transition-all duration-300 lg:translate-y-[3%] lg:translate-x-[10%] 2xl:translate-x-[0%] 2xl:translate-y-[3%]"
              />
              {/* Click to Play Text */}
            <p className="absolute bottom-[-24px] left-1/2 transform -translate-x-1/2
                          text-sm text-white/70 whitespace-nowrap
                          animate-pulse xl:translate-x-[-32%] 2xl:translate-x-[-50%] xl:translate-y-[60%] 2xl:translate-y-[60%]">
              {/* Click Play Button to know more */}
            </p>
            </div>
          </div>
        </div>
      </div>

     
      {showDownloadPopup && (
        <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-50">
          <div className="bg-black/80 backdrop-blur-xl border-2 border-red-900/30 rounded-xl p-8 max-w-sm w-full mx-4
                        transform transition-all duration-500 hover:scale-[1.02]
                        hover:shadow-[0_0_50px_rgba(255,0,0,0.2)]">
            <div className="text-center space-y-4">
              <h3 className="text-2xl font-bold text-white/90">Download App</h3>
              <p className="text-sm text-white/80 text-5xl md:text-l">
                To continue shopping,
              </p>
              <p className="text-sm text-white/80 text-5xl md:text-l translate-y-[-70%]">
                download the Zulu Club App
              </p>
              
              <div className="flex flex-col gap-3 mt-">
                <a 
                  href="https://play.google.com/store/apps/details?id=com.zulu.consumer.zulu_consumer"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center gap-2 
                           bg-white/10 backdrop-blur-sm border-2 border-red-900/20 rounded-lg px-6 py-3
                           hover:bg-white/20 hover:border-red-800/40
                           hover:shadow-[0_4px_16px_rgba(255,0,0,0.25)]
                           transition-all duration-300"
                >
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
                  </svg>
                  <span className="text-white/90">Get it on Google Play</span>
                </a>
                
                <a 
                  href="https://apps.apple.com/in/app/zulu-club/id6739531325"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center gap-2 
                           bg-white/10 backdrop-blur-sm border-2 border-red-900/20 rounded-lg px-6 py-3
                           hover:bg-white/20 hover:border-red-800/40
                           hover:shadow-[0_4px_16px_rgba(255,0,0,0.25)]
                           transition-all duration-300"
                >
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11"/>
                  </svg>
                  <span className="text-white/90">Download on App Store</span>
                </a>
              </div>

              <button
                onClick={() => setShowDownloadPopup(false)}
                className="mt-6 text-sm text-white/70 hover:text-white transition-colors duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Video Modal */}
      {showVideo && (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 p-4">
          <div className="relative w-full max-w-[80vw] lg:max-w-[60vw]">
            <button 
              onClick={() => setShowVideo(false)}
              className="absolute -top-10 right-0 text-white/80 hover:text-white
                        text-lg font-semibold"
            >
              Close
            </button>
            <div className="aspect-video">
              <video 
                controls 
                autoPlay 
                className="w-full h-full rounded-lg object-contain
                         shadow-lg shadow-black/50"
              >
                <source src={appintro} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )}

      {/* Animation styles */}
      <style jsx>{`
        @keyframes float {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-5px);
          }
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }

        @keyframes subtle-glow {
          0%, 100% { opacity: 0.3; }
          50% { opacity: 0.5; }
        }
        
        .animate-subtle-glow {
          animation: subtle-glow 3s ease-in-out infinite;
        }

        @keyframes custom-pulse {
          0%, 100% {
            opacity: 1;
          }
          50% {
            opacity: 0.7;
          }
        }
        
        .animate-pulse {
          animation: custom-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      `}</style>
    </div>
  );
});

export default HeroSection;