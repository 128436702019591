import React, { useState, useEffect } from 'react';
import videoTourVideo from '../assets/videotourtrim.mp4'; // Update with your video tour image
import DownloadAppPopup from './DownloadAppPopup';

const RunVideoTour = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Mobile Layout
  const MobileLayout = () => (
    <div className="bg-black py-8 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Mobile Text Content */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-white mb-4">
            Take a Video Tour
          </h2>
          
          <p className="text-lg text-gray-300 mb-6 leading-relaxed">
            Get a quick walk through of the full collection & shop live with assistance
          </p>

          <button 
            className="bg-white/10 backdrop-blur-sm hover:bg-white/20 
                       text-white font-semibold py-2.5 px-6
                       rounded-lg border border-white/20
                       transition-all duration-300 ease-in-out"
            onClick={() => setIsOpen(true)}
          >
            Get Started
          </button>
        </div>

        {/* Mobile Video Card */}
        <div className="relative aspect-[3/4] max-w-lg mx-auto w-[90%] h-[605px]">
          <div className="relative w-full h-full rounded-xl overflow-hidden 
                        bg-white/5 backdrop-blur-sm border border-black">
            <video
              src={videoTourVideo}
              alt="Video Tour"
              className="w-full h-full object-cover"
              autoPlay
              loop
              muted
              playsInline
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
              <div className="absolute bottom-4 left-4 right-4">
                {/* <h3 className="text-xl font-bold text-white mb-2">
                  Live Store Tours
                </h3> */}
                {/* <p className="text-sm text-gray-300">
                  Experience stores in real-time
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Features List */}
        <div className="mt-8 space-y-4 px-4">
          <div className="text-center">
            {/* <h3 className="text-lg font-semibold text-white mb-2">
              Live Shopping Experience
            </h3>
            <p className="text-sm text-gray-300">
              Connect with personal shoppers through live video calls
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );

  // Return either mobile or desktop layout
  return (
    <>
      {isMobile ? (
        <MobileLayout />
      ) : (
        // Original Desktop Layout
        <div className="bg-black py-16 px-8">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center gap-12">
              {/* Video Tour Card - Now on Left */}
              <div className="w-full lg:w-1/2 translate-y-[15%]">
                <div className="relative aspect-[3/4] max-w-lg mx-auto w-[310px] h-[540px]">
                  <div className="relative w-full h-full rounded-lg overflow-hidden 
                                bg-white/5 backdrop-blur-sm border border-black">
                    <video
                      src={videoTourVideo}
                      alt="Video Tour"
                      className="w-full h-full object-cover"
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
                      <div className="absolute bottom-6 left-6 right-6">
                        {/* <h3 className="text-2xl font-bold text-white mb-2">
                          Book a Video Tour
                        </h3> */}
                        {/* <p className="text-gray-300">
                          Experience stores in real-time
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Text Content - Now on Right */}
              <div className="w-full lg:w-1/2 text-center lg:text-left xl:translate-y-[15%] xl:translate-x-[-100px] 2xl:translate-x-[0px]">
                <h2 className="text-4xl md:text-4xl font-bold text-white mb-6 translate-x-[129px]">
                  Take a Video Tour 
                </h2>
                
                 
                 <p className="text-2xl text-gray-300 mb-8 leading-relaxed translate-x-[125px]">
                  Get a quick walk through of the full <br></br>collection & shop live with assistance
                </p>

                {/* Get Started Button */}
                <div className="translate-x-[125px] translate-y-[20px] mb-12">
                  <button 
                    className="bg-white/10 backdrop-blur-sm hover:bg-white/20 
                               text-white font-semibold py-3 px-8
                               rounded-lg border border-white/20
                               transition-all duration-300 ease-in-out
                               transform hover:scale-105"
                    onClick={() => setIsOpen(true)}
                  >
                    Get Started
                  </button>
                </div>

                {/* Live Shopping Features */}
                <div className="mb-8 translate-x-[100px]">
                  {/* <h3 className="text-xl font-semibold text-white mb-4">
                    Live Shopping Experience
                  </h3> */}
                  {/* <ul className="space-y-2 text-gray-300">
                    <li>Connect with personal shoppers through live video calls and explore fashion collections in real-time</li>
                  </ul> */}
                </div>

                {/* Store Tours */}
                <div className="mb-8 translate-x-[100px]">
                  {/* <h3 className="text-xl font-semibold text-white mb-4">
                    Virtual Store Tours
                  </h3> */}
                  {/* <ul className="space-y-2 text-gray-300">
                    <li>Connect with personal shoppers through live video calls and explore fashion collections in real-time</li>
                  </ul> */}
                </div>

                {/* Interactive Features */}
                <div className="mb-8 translate-x-[100px]">
                  {/* <h3 className="text-xl font-semibold text-white mb-4">
                    Interactive Shopping
                  </h3> */}
                  {/* <ul className="space-y-2 text-gray-300">
                    <li>Connect with personal shoppers through live video calls and explore fashion collections in real-time</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DownloadAppPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default RunVideoTour; 