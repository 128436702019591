import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConsumerPage from './pages/consumer_page';
import AskZuluPage from './pages/AskZuluPage';
import BrandsPage from './pages/BrandsPage';
import ContactSalesPage from './pages/ContactSalesPage';
import AboutUsPage from './pages/AboutUsPage';
// ... other imports

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ConsumerPage />} />
        <Route path="/ask-zulu" element={<AskZuluPage />} />
        <Route path="/brands" element={<BrandsPage />} />
        <Route path="/contact-sales" element={<ContactSalesPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        {/* ... other routes */}
      </Routes>
    </Router>
  );
}

export default App; 