import React, { useState, useRef, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ConversationalAgent from '../agents/ConversationalAgent';
import { MessageCircle, Send, ExternalLink } from 'lucide-react';
import ReactGA from 'react-ga4';


const DownloadAppPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white/10 backdrop-blur-lg border border-white/10 rounded-lg p-6 max-w-sm w-full mx-4">
        <div className="text-center space-y-4">
          <h3 className="text-xl font-bold text-white">Download Zulu App</h3>
          <p className="text-sm text-gray-300">
            To view product details and make purchases, please download our mobile app
          </p>
          
          <div className="flex flex-col gap-3 mt-6">
            <a 
              href="https://play.google.com/store/apps/details?id=com.zulu.consumer.zulu_consumer"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 bg-white/10 hover:bg-white/20 
                       text-white rounded-lg px-4 py-2 transition-all duration-200"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
              </svg>
              Get it on Google Play
            </a>
            
            <a 
              href="https://apps.apple.com/in/app/zulu-club/id6739531325"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 bg-white/10 hover:bg-white/20 
                       text-white rounded-lg px-4 py-2 transition-all duration-200"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11"/>
              </svg>
              Download on App Store
            </a>
          </div>

          <button
            onClick={onClose}
            className="mt-6 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const ProductCard = ({ product, explanation }) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  //console.log('Full product data:', JSON.stringify(product, null, 2));

  // Handle both data structures (filtered products and recommendations)
  const {
    id,
    basic_info,
    // For recommendations response
    name: directName,
    brand: directBrand,
    price: directPrice,
    image_url: directImageUrl,
    product_url: directProductUrl,
    mall_name: directMall,
    // Additional price fields that might be at root level
    discounted_price: rootDiscountedPrice,
    mrp: rootMrp
  } = product || {};

  // If basic_info exists, use the filtered products structure
  if (basic_info) {
    const {
      brand = { detected: 'Unknown Brand' },
      product_details = {},
      price = {}
    } = basic_info;

    const {
      name = 'Unknown Product',
      image_url = '',
      link: product_url = '',
      mall_name: mall = ''
    } = product_details;

    const {
      discounted_price: currentPrice = '',
      mrp: originalPrice = ''
    } = price;

    const handleProductClick = (e) => {
      e.preventDefault();
      setShowDownloadPopup(true);
    };

    return (
      <>
        <div 
          onClick={handleProductClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="relative bg-white/10 rounded-lg overflow-hidden hover:bg-white/20 
                   transition-all duration-200 cursor-pointer"
        >
          {image_url && (
            <div className="relative h-52">
              <img 
                src={image_url}
                alt={name}
                className="w-full h-full object-cover"
                onError={(e) => {
                  console.log('Image load error:', image_url);
                  e.target.onerror = null;
                  e.target.src = '/placeholder-image.png';
                }}
              />
            </div>
          )}
          <div className="p-2">
            <p className="text-[11px] text-gray-400">{brand.detected}</p>
            <h3 className="text-xs font-medium text-white truncate mt-0.5">{name}</h3>
            {mall && <p className="text-[11px] text-gray-400 mt-0.5">{mall}</p>}
            <div className="mt-1 flex justify-between items-center">
              <div className="flex items-center gap-1">
                <p className="text-xs font-medium text-white">
                  ₹{currentPrice || originalPrice || 'N/A'}
                </p>
                {originalPrice && currentPrice && originalPrice !== currentPrice && (
                  <p className="text-[10px] text-gray-400 line-through">₹{originalPrice}</p>
                )}
              </div>
              {product_url && (
                <span className="text-blue-400 hover:text-blue-300">
                  <ExternalLink size={12} />
                </span>
              )}
            </div>
          </div>

          {/* Hover Explanation Popup - Slide Up */}
          {isHovered && explanation && (
            <div className="absolute inset-x-0 bottom-0 bg-black/90 backdrop-blur-sm
                          transform transition-all duration-300 ease-out
                          flex items-center justify-center p-4
                          border-t border-white/10">
              <p className="text-sm text-white/90 leading-relaxed text-center">
                {explanation}
              </p>
            </div>
          )}
        </div>
        <DownloadAppPopup 
          isOpen={showDownloadPopup} 
          onClose={() => setShowDownloadPopup(false)} 
        />
      </>
    );
  }

  // For recommendations response structure
  const finalPrice = {
    discounted_price: directPrice?.discounted_price || rootDiscountedPrice || '',
    mrp: directPrice?.mrp || rootMrp || ''
  };

  // Get image URL from product.basic_info structure for recommendations
  const imageUrl = product?.basic_info?.product_details?.image_url || directImageUrl || '';
  console.log('Resolved image URL:', imageUrl);

  const handleProductClick = (e) => {
    e.preventDefault();
    setShowDownloadPopup(true);
  };

  return (
    <>
      <div 
        onClick={handleProductClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="relative bg-white/10 rounded-lg overflow-hidden hover:bg-white/20 
                 transition-all duration-200 cursor-pointer"
      >
        {imageUrl && (
          <div className="relative h-52">
            <img 
              src={imageUrl}
              alt={directName || 'Product image'}
              className="w-full h-full object-cover"
              onError={(e) => {
                console.log('Image load error:', imageUrl);
                e.target.onerror = null;
                e.target.src = '/placeholder-image.png';
              }}
            />
          </div>
        )}
        <div className="p-2">
          <p className="text-[11px] text-gray-400">{directBrand || product?.basic_info?.brand?.detected || 'Unknown Brand'}</p>
          <h3 className="text-xs font-medium text-white truncate mt-0.5">
            {directName || product?.basic_info?.product_details?.name || 'Unknown Product'}
          </h3>
          {directMall && <p className="text-[11px] text-gray-400 mt-0.5">{directMall}</p>}
          <div className="mt-1 flex justify-between items-center">
            <div className="flex items-center gap-1">
              <p className="text-xs font-medium text-white">
                ₹{finalPrice.discounted_price || finalPrice.mrp || 'N/A'}
              </p>
              {finalPrice.mrp && finalPrice.discounted_price && 
               finalPrice.mrp !== finalPrice.discounted_price && (
                <p className="text-[10px] text-gray-400 line-through">₹{finalPrice.mrp}</p>
              )}
            </div>
            {(directProductUrl || product?.basic_info?.product_details?.link) && (
              <span className="text-blue-400 hover:text-blue-300">
                <ExternalLink size={12} />
              </span>
            )}
          </div>
        </div>

        {/* Hover Explanation Popup - Slide Up */}
        {isHovered && explanation && (
          <div className="absolute inset-x-0 bottom-0 bg-black/90 backdrop-blur-sm
                        transform transition-all duration-300 ease-out
                        flex items-center justify-center p-4
                        border-t border-white/10">
            <p className="text-sm text-white/90 leading-relaxed text-center">
              {explanation}
            </p>
          </div>
        )}
      </div>
      <DownloadAppPopup 
        isOpen={showDownloadPopup} 
        onClose={() => setShowDownloadPopup(false)} 
      />
    </>
  );
};

const RecommendationsSection = ({ recommendedProducts, explanations, styleSuggestions }) => {
  // Clean style suggestions by removing product IDs and any references
  const cleanStyleSuggestions = styleSuggestions?.map(suggestion => {
    return suggestion
      // Remove product IDs in various formats (#123, [123], (123))
      .replace(/#\d+|\[\d+\]|\(\d+\)/g, '')
      // Remove any remaining brackets and parentheses
      .replace(/[\[\]\(\)]/g, '')
      // Remove any double spaces created
      .replace(/\s+/g, ' ')
      // Trim whitespace
      .trim();
  }).filter(suggestion => 
    // Remove empty suggestions and those that are just spaces
    suggestion.length > 0 && suggestion !== ' '
  );

  console.log('Raw recommended products:', recommendedProducts);

  if (!recommendedProducts || recommendedProducts.length === 0) {
    console.log('No recommendations to display');
    return null;
  }

  // Map the raw product data to the expected structure
  const formattedProducts = recommendedProducts.map(product => {
    console.log('Raw product data:', product); // Debug log

    // Handle both possible data structures
    const zulu_data = product.zulu_data || product;
    
    return {
      id: product.id,
      basic_info: {
        product_details: {
          name: zulu_data.name || 'Unknown Product',
          image_url: zulu_data.image_url || '',
          link: zulu_data.product_url || '',
          mall_name: zulu_data.mall || zulu_data.mall_name || '',
          product_url: zulu_data.product_url || ''
        },
        brand: {
          detected: zulu_data.brand || 'Unknown Brand'
        },
        price: {
          mrp: zulu_data.price?.mrp || product.price?.mrp || '',
          discounted_price: zulu_data.price?.discounted_price || product.price?.discounted_price || ''
        },
        classification: {
          category: {
            main: zulu_data.category || '',
            sub: zulu_data.sub_category || ''
          }
        }
      }
    };
  });

  console.log('Formatted products:', formattedProducts);

  return (
    <div className="mt-8 bg-white/5 rounded-lg p-6">
      <div className="mb-4">
        <h2 className="text-xl font-bold text-white mb-2">Recommended For You</h2>
        {cleanStyleSuggestions && cleanStyleSuggestions.length > 0 && (
          <div className="text-sm text-gray-300 mb-4">
            <ul className="list-disc pl-4">
              {cleanStyleSuggestions.map((suggestion, index) => (
                <li key={index} className="text-sm leading-relaxed">{suggestion}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {formattedProducts.map((product, index) => (
          <ProductCard 
            key={`${product.id}-${index}`}
            product={product} 
            explanation={explanations[product.id]}
          />
        ))}
      </div>
    </div>
  );
};

const ProductsDisplay = ({ title, products, explanation = null }) => {
  if (!products || products.length === 0) return null;

  return (
    <div className="mt-8">
      {explanation && (
        <div className="bg-white/5 rounded-lg p-6 mb-6">
          <p className="text-gray-300 leading-relaxed">{explanation}</p>
        </div>
      )}
      <h2 className="text-2xl font-bold text-white mb-4">{title}</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
        {products.map((product, index) => (
          <ProductCard 
            key={`filtered-${index}`} 
            product={product} 
          />
        ))}
      </div>
    </div>
  );
};

const AskZuluPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [recommendationExplanations, setRecommendationExplanations] = useState({});
  const [styleSuggestions, setStyleSuggestions] = useState('');
  const agent = useRef(new ConversationalAgent());

  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize('G-BXQZ9G9TGL');
    
    // Send pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });

    // Add initial greeting
    if (messages.length === 0) {
      setMessages([
        {
          text: "Hi! Welcome to Zulu Club. What can I assist you with today?",
          sender: 'ai'
        }
      ]);
    }
  }, [messages.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isLoading) return;

    const userMessage = {
      text: inputValue.trim(),
      sender: 'user'
    };

    try {
      setIsLoading(true);
      setMessages(prev => [...prev, userMessage]);
      setInputValue('');

      const response = await agent.current.handleQuery(
        userMessage.text,
        messages
      );

      console.log('Response from agent:', response);

      // Clear previous recommendations
      setRecommendedProducts([]);
      setRecommendationExplanations({});
      setStyleSuggestions([]);

      // Check for recommendations
      if (response.recommendations) {
        console.log('Processing recommendations:', response.recommendations);
        
        // Update recommendations state
        if (response.recommendations.products) {
          console.log('Setting recommended products:', response.recommendations.products);
          setRecommendedProducts(response.recommendations.products);
        }
        
        if (response.recommendations.explanations) {
          console.log('Setting explanations:', response.recommendations.explanations);
          setRecommendationExplanations(response.recommendations.explanations);
        }
        
        if (response.recommendations.style_suggestions) {
          console.log('Setting style suggestions:', response.recommendations.style_suggestions);
          setStyleSuggestions(response.recommendations.style_suggestions);
        }
      }

      // Update filtered products if available
      if (response.filteredProducts) {
        console.log('Setting filtered products:', response.filteredProducts);
        // Limit to first 8 products (or any number you prefer)
        const limitedProducts = response.filteredProducts.slice(0,20);
        setFilteredProducts(limitedProducts);
      }

      // Add AI response to messages
      const aiMessage = {
        text: response.reply,
        sender: 'ai',
        context: response.context
      };

      setMessages(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Chat error:', error);
      setMessages(prev => [...prev, {
        text: "I'm sorry, I encountered an error. Please try again.",
        sender: 'ai'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-black min-h-screen">
      <Navbar />
      <div className="pt-16 sm:pt-24 px-2 sm:px-4 max-w-[1920px] mx-auto">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
          
          {/* Chat Container - Full width on mobile */}
          <div className="w-full lg:w-[40%] lg:sticky lg:top-24 lg:self-start">
            <div className="bg-white/5 rounded-lg overflow-hidden">
              {/* Chat Header */}
              <div className="bg-white/10 p-3 sm:p-4 border-b border-white/10">
                <div className="flex items-center gap-2">
                  <MessageCircle className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400" />
                  <h1 className="text-base sm:text-lg font-bold text-white">Ask Zulu</h1>
                </div>
              </div>

              {/* Chat Content */}
              <div className="p-3 sm:p-4 min-h-[60vh] lg:min-h-[calc(100vh-200px)] flex flex-col">
                {/* Messages Area - Adjusted height for mobile */}
                <div className="flex-1 overflow-y-auto mb-4 space-y-2 sm:space-y-3 
                              h-[50vh] lg:h-[calc(100vh-350px)]">
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-[85%] sm:max-w-[80%] rounded-lg px-2.5 sm:px-3 py-1.5 ${
                          message.sender === 'user'
                            ? 'bg-white/10 text-white'
                            : 'bg-white/5 text-gray-200'
                        }`}
                      >
                        <p className="text-sm">{message.text}</p>
                      </div>
                    </div>
                  ))}
                  {isLoading && (
                    <div className="flex justify-start">
                      <div className="bg-white/5 text-gray-200 rounded-lg px-3 py-1.5">
                        <p className="text-sm">Thinking...</p>
                      </div>
                    </div>
                  )}
                </div>

                {/* Input Form - Adjusted for better visibility */}
                <form onSubmit={handleSubmit} className="mt-auto">
                  <div className="flex gap-3">
                    <input
                      type="text"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Ask about products..."
                      className="flex-1 bg-white rounded-xl px-3 py-2 
                               text-base sm:text-lg text-black placeholder:text-gray-500
                               border-2 border-white hover:border-gray-300
                               focus:outline-none focus:border-blue-400 focus:ring-2 focus:ring-blue-400/20
                               transition-all duration-300
                               shadow-lg"
                      disabled={isLoading}
                    />
                    <button
                      type="submit"
                      disabled={isLoading || !inputValue.trim()}
                      className="bg-white/10 hover:bg-white/20 text-white 
                               px-4 py-3 rounded-xl
                               transition-all duration-300
                               disabled:opacity-50 disabled:cursor-not-allowed
                               border border-white/20 hover:border-white/40
                               shadow-lg hover:shadow-xl"
                    >
                      <Send size={20} className="sm:w-5 sm:h-5" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Products Display Section - Adjusted vertical alignment */}
          <div className="flex-1 mt-4 lg:-mt-[2%] lg:w-[60%]"> {/* Added negative margin for desktop */}
            {/* Recommendations Section */}
            {recommendedProducts && recommendedProducts.length > 0 && (
              <RecommendationsSection 
                recommendedProducts={recommendedProducts}
                explanations={recommendationExplanations}
                styleSuggestions={styleSuggestions}
              />
            )}

            {/* Show Filtered Products Section */}
            {filteredProducts && filteredProducts.length > 0 && (
              <div className="mt-8">
                <ProductsDisplay 
                  title="Similar Products"
                  products={filteredProducts}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AskZuluPage; 