import React from 'react';
import { Instagram, Linkedin } from 'lucide-react';
import team1 from '../assets/Adarsh.png';
import team2 from '../assets/Aman.png';
import team3 from '../assets/Shivang.jpeg';
import team4 from '../assets/Ravit.png';

const TeamSection = () => {
  const teamMembers = [
    {
      id: 1,
      name: "Adarsh Bhatia",
      role: "Operations",
      image: team1,
      instagram: "https://www.instagram.com/adarshbhatia?igsh=NzYzbzFydnFwdmxt",
      linkedin: "https://linkedin.com/in/adarsh-bhatia-3293ba21/"
    },
    {
      id: 2,
      name: "Aman Taneja",
      role: "Customer Experience",
      image: team2,
      instagram: "https://www.instagram.com/amantaneja7?igsh=dXRjN3FzMnF2eDM5",
      linkedin: "https://linkedin.com/in/aman-taneja-fms/"
    },
    {
      id: 3,
      name: "Shivang Shekhar",
      role: "Data Science",
      image: team3,
      instagram: "https://www.instagram.com/shivangshekhar99?igsh=YmFra3B4dWFqNWZ3",
      linkedin: "https://linkedin.com/in/shivang-shekhar-4450791ab/"
    },
    {
      id: 4,
      name: "Ravit Thakral",
      role: "Product",
      image: team4,
      instagram: "https://www.instagram.com/ravit.thakral?igsh=OTh6NDdyMGY3dmQ2",
      linkedin: "https://linkedin.com/in/ravit-thakral/"
    }
  ];

  return (
    <div className="bg-black py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-white text-center mb-4">
          Meet Our Team
        </h1>
        <p className="text-center text-lg md:text-xl text-gray-300 font-light mb-10">
          The People Behind Zulu
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          {teamMembers.map((member) => (
            <div
              key={member.id}
              className="relative transform transition duration-300 hover:scale-105"
            >
              <div className="relative rounded-lg overflow-hidden bg-white/5 border border-white/10">
                <img
                  src={member.image}
                  alt={member.name}
                  className={`w-full object-cover ${
                    member.name === "Aman Taneja" 
                      ? 'h-[280px] md:h-[320px] object-top pt-0'
                      : member.name === "Shivang Shekhar"
                      ? 'h-[280px] md:h-[320px] object-[45%] pt-0'
                      : 'h-[280px] md:h-[320px]'
                  }`}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                  <div className="absolute inset-x-0 bottom-0 p-4">
                    <h2 className="text-lg md:text-xl font-bold mb-1 text-white">
                      {member.name}
                    </h2>
                    <p className="text-sm text-gray-300 mb-3">
                      {member.role}
                    </p>
                    <div className="flex space-x-3">
                      <a
                        href={member.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-300 hover:text-white transition-colors duration-200"
                      >
                        <Instagram className="w-5 h-5" />
                      </a>
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-300 hover:text-white transition-colors duration-200"
                      >
                        <Linkedin className="w-5 h-5" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamSection; 