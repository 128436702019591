import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TeamSection from '../components/TeamSection';
import teaser from '../assets/Zulu Teaser 2.mp4';
import DownloadAppPopup from '../components/DownloadAppPopup';
import Adarsh from '../assets/Adarsh.png';
import Aman from '../assets/Aman.png';
import Shivang from '../assets/Shivang.jpeg';
import Ravit from '../assets/Ravit.png';
import ReactGA from 'react-ga4';

const AboutUsPage = () => {
    const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize('G-BXQZ9G9TGL');
    
    // Send pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black">
      <Helmet>
        <title>About Us - Zulu</title>
      </Helmet>
      
      <Navbar />
      
      {/* Hero Section with Video */}
      <div className="relative min-h-[80vh] flex items-center justify-center overflow-hidden">
        {/* Video Background */}
        <div className="absolute inset-0 w-full h-full">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full h-full object-cover"
            src={teaser}
          />
          {/* Overlay */}
          <div className="absolute inset-0 bg-black/50"></div>
        </div>

        {/* Content */}
        <div className="relative z-10 container mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6
                       bg-gradient-to-r from-white via-gray-100 to-white 
                       bg-clip-text text-transparent">
            Fashion Concierge
          </h1>
          <p className="text-gray-300 text-lg md:text-xl max-w-3xl mx-auto">
            Zulu is transforming the fashion retail landscape by bridging the gap between 
            offline stores and online discovery.
          </p>
        </div>
      </div>

      {/* About Zulu Section */}
      <div className="py-16 md:py-24">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto space-y-8">
            <h2 className="text-3xl md:text-4xl font-bold text-white text-center">
              Our Story
            </h2>
            <p className="text-gray-300 text-lg leading-relaxed">
              Zulu Club was founded to bridge the gap between the joy of traditional mall shopping and the convenience of online shopping. We believe shopping should be fun, personal, and hassle-free no matter how busy life gets.
            </p>
          </div>
        </div>
      </div>

      {/* How Zulu Works Section */}
      <div className="py-16 md:py-24 bg-white/5">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-white text-center mb-16">
              What Makes Us Different?
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white/5 p-8 rounded-2xl border border-white/10 hover:bg-white/[0.07] transition-all duration-300">
                <h3 className="text-xl font-semibold text-white mb-4">Live Video Shopping</h3>
                <p className="text-gray-300">
                  Enjoy real-time video tours guided by personal shoppers who help you find exactly what you need.
                </p>
              </div>
              <div className="bg-white/5 p-8 rounded-2xl border border-white/10 hover:bg-white/[0.07] transition-all duration-300">
                <h3 className="text-xl font-semibold text-white mb-4">Fast Delivery</h3>
                <p className="text-gray-300">
                  Get your purchases delivered to your doorstep within just 100 minutes.
                </p>
              </div>
              <div className="bg-white/5 p-8 rounded-2xl border border-white/10 hover:bg-white/[0.07] transition-all duration-300">
                <h3 className="text-xl font-semibold text-white mb-4">Ask Zulu</h3>
                <p className="text-gray-300">
                  Chat with a real person or AI assistant for instant shopping assistance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Zulu Section */}
      <div className="py-16 md:py-24">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-white text-center mb-16">
              Why Choose Zulu
            </h2>
            <div className="grid md:grid-cols-2 gap-12">
              <div className="space-y-6">
                <h3 className="text-2xl font-semibold text-white">Save Valuable Time</h3>
                <p className="text-gray-300 text-lg">
                  Skip the hassle of traffic, parking queues, trial room waits, and billing lines. 
                  Shop from home and save your weekends.
                </p>
              </div>
              <div className="space-y-6">
                <h3 className="text-2xl font-semibold text-white">Unmatched Variety</h3>
                <p className="text-gray-300 text-lg">
                  Access a vast range of products, including Indian ethnic wear, premium brands, 
                  local favorites, and exclusive legacy outlets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Team Section - Added here */}
      <TeamSection />

      <Footer />
      <DownloadAppPopup 
        isOpen={showDownloadPopup} 
        onClose={() => setShowDownloadPopup(false)} 
      />
    </div>
  );
};

export default AboutUsPage;
