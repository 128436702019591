import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import chatbotVideo from '../assets/askzulutrim.mp4';

// Update with your chatbot image

const RunAskZulu = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Mobile Layout
  const MobileLayout = () => (
    <div className="bg-black py-8 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Mobile Text Content */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-white mb-4">
            Ask Zulu Anything
          </h2>
          
          <p className="text-lg text-gray-300 mb-6 leading-relaxed">
            Let the Zulu AI assistant be your personal guide for smarter shopping
          </p>

          <button 
            className="bg-white/10 backdrop-blur-sm hover:bg-white/20 
                       text-white font-semibold py-2.5 px-6
                       rounded-lg border border-white/20
                       transition-all duration-300 ease-in-out"
            onClick={() => navigate('/ask-zulu')}
          >
            Chat Now
          </button>
        </div>

        {/* Mobile Chatbot Card */}
        <div className="relative aspect-[3/4] max-w-lg mx-auto w-[90%] h-[605px]">
          <div className="relative w-full h-full rounded-xl overflow-hidden 
                        bg-white/5 backdrop-blur-sm border border-black">
            <video
              src={chatbotVideo}
              className="w-full h-full object-cover"
              autoPlay
              loop
              muted
              playsInline
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
              <div className="absolute bottom-4 left-4 right-4">
                {/* <h3 className="text-xl font-bold text-white mb-2">
                  AI Assistant
                </h3>
                <p className="text-sm text-gray-300">
                  Get personalized recommendations
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Features List */}
        <div className="mt-8 space-y-4 px-4">
          <div className="text-center">
            {/* <h3 className="text-lg font-semibold text-white mb-2">
              Smart Shopping Assistant
            </h3>
            <p className="text-sm text-gray-300">
              Get instant answers to all your shopping queries
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );

  // Return either mobile or desktop layout
  return (
    <>
      {isMobile ? (
        <MobileLayout />
      ) : (
        // Original Desktop Layout
        <div className="bg-black py-16 px-8">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center gap-12">
              <div className="w-full lg:w-1/2 text-center lg:text-left translate-y-[15%]">
                <h2 className="text-4xl md:text-4xl font-bold text-white mb-6 translate-x-[130px]">
                  Ask Zulu Anything
                </h2>
                
                {/* Main Description */}
                <p className="text-2xl text-gray-300 mb-8 leading-relaxed translate-x-[125px]">
                  Let the Zulu AI assistant be your personal <br></br> guide for smarter shopping
                </p>

                {/* Chat Now Button */}
                <div className="translate-x-[125px] translate-y-[20px] mb-12">
                  <button 
                    className="bg-white/10 backdrop-blur-sm hover:bg-white/20 
                               text-white font-semibold py-3 px-8
                               rounded-lg border border-white/20
                               transition-all duration-300 ease-in-out
                               transform hover:scale-105"
                    onClick={() => navigate('/ask-zulu')}
                  >
                    Chat Now
                  </button>
                </div>
              </div>
              
              {/* Right side - Chatbot Card */}
              <div className="w-full lg:w-1/2">
                <div className="relative aspect-[3/4] max-w-lg mx-auto w-[310px] h-[540px] translate-x-[30px] translate-y-[15%]">
                  <div className="relative w-full h-full rounded-lg overflow-hidden 
                                bg-white/5 backdrop-blur-sm border border-black">
                    <video
                      src={chatbotVideo}
                      className="w-full h-full object-cover"
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
                      <div className="absolute bottom-6 left-6 right-6">
                        {/* <h3 className="text-2xl font-bold text-white mb-2">
                          AI Assistant
                        </h3>
                        <p className="text-gray-300">
                          Get personalized recommendations
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RunAskZulu; 