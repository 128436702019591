import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const ContactSalesPage = () => {
  // Add useEffect to scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    phoneNumber: '',
    brandName: '',
    hasStore: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const timestamp = new Date().toISOString();
      const jsonData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        workEmail: formData.workEmail,
        phoneNumber: formData.phoneNumber,
        brandName: formData.brandName,
        hasStore: formData.hasStore,
        timestamp: timestamp,
        type: 'Contact Sales'
      };

      const response = await fetch('https://hooks.zapier.com/hooks/catch/21065465/28s8lvn/', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData)
      });

      console.log('Form submitted successfully');
      
      // Reset form
      setFormData({
        firstName: '',
        lastName: '',
        workEmail: '',
        phoneNumber: '',
        brandName: '',
        hasStore: ''
      });

      // Show success message
      alert('Thank you for your interest! Our team will contact you soon.');

    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      <Helmet>
        <title>Contact Us - Zulu</title>
      </Helmet>
      
      <Navbar />
      
      <div className="py-12 sm:py-16 md:py-20 lg:py-24">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="text-center mb-12 md:mb-16">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold 
                         bg-gradient-to-r from-white via-gray-100 to-white 
                         bg-clip-text text-transparent mb-4">
              Contact Us
            </h1>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Fill your details and our team will reach out to you at the earliest			
            </p>
          </div>

          {/* Contact Form */}
          <div className="max-w-2xl mx-auto">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* First Name and Last Name */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="firstName" className="block text-white mb-2">
                    First Name *
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 
                             rounded-lg text-white focus:outline-none 
                             focus:border-white/20 transition-colors"
                    placeholder="Enter your first name"
                  />
                </div>

                <div>
                  <label htmlFor="lastName" className="block text-white mb-2">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 
                             rounded-lg text-white focus:outline-none 
                             focus:border-white/20 transition-colors"
                    placeholder="Enter your last name"
                  />
                </div>
              </div>

              {/* Work Email and Phone Number */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="workEmail" className="block text-white mb-2">
                    Work Email *
                  </label>
                  <input
                    type="email"
                    id="workEmail"
                    name="workEmail"
                    value={formData.workEmail}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 
                             rounded-lg text-white focus:outline-none 
                             focus:border-white/20 transition-colors"
                    placeholder="work@company.com"
                  />
                </div>

                <div>
                  <label htmlFor="phoneNumber" className="block text-white mb-2">
                    Phone Number *
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 bg-white/5 border border-white/10 
                             rounded-lg text-white focus:outline-none 
                             focus:border-white/20 transition-colors"
                    placeholder="Enter your phone number"
                  />
                </div>
              </div>

              {/* Brand Name */}
              <div>
                <label htmlFor="brandName" className="block text-white mb-2">
                  Brand Name *
                </label>
                <input
                  type="text"
                  id="brandName"
                  name="brandName"
                  value={formData.brandName}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 
                           rounded-lg text-white focus:outline-none 
                           focus:border-white/20 transition-colors"
                  placeholder="Enter your brand name"
                />
              </div>

              {/* Store in Gurugram Dropdown */}
              <div>
                <label htmlFor="hasStore" className="block text-white mb-2">
                  Do you have a physical store in Gurugram? *
                </label>
                <select
                  id="hasStore"
                  name="hasStore"
                  value={formData.hasStore}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 
                           rounded-lg text-white focus:outline-none 
                           focus:border-white/20 transition-colors
                           appearance-none cursor-pointer"
                >
                  <option value="" disabled>Select an option</option>
                  <option value="yes" className="bg-black">Yes</option>
                  <option value="no" className="bg-black">No</option>
                </select>
              </div>

              {/* Submit Button */}
              <div className="flex justify-center pt-6">
                <button
                  type="submit"
                  className="px-8 py-3 bg-white text-black rounded-lg 
                           transform transition-all duration-300 
                           hover:scale-105 hover:bg-white/90
                           font-medium text-lg"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactSalesPage; 