import React, { useState } from 'react';
import DownloadAppPopup from './DownloadAppPopup';

const BrandsGrid = () => {
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  const brandItems = [
    {
      storeName: "Zara Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/10001835651.webp",
      brandLogo: "https://zulushop.in/uploads/seller/Zara_Logo1.png",
    },
    {
      storeName: "Uniqlo Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000183708.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/UNIQLO_Logo.png",
    },
    {
      storeName: "H & M Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000183604.webp",
      brandLogo: "https://zulushop.in/uploads/seller/H_M_Logo.png",
    },
    {
      storeName: "Newme Airia Mall",
      description: "Women",
      imageUrl: "https://zulushop.in/uploads/media/2025/1000158080.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Newme_Logo1.png",
    },
    {
      storeName: "Skechers Airia Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000139511.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Skechers1.png",
    },
    {
      storeName: "Jack & Jones Airia Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000105421.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Jack_Jones_logo.png",
    },
    {
      storeName: "Chique Airia Mall",
      description: "Women",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000102478.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Chique_logo.png",
    },
    {
      storeName: "Tommy Hilfiger Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2025/1000185161.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Tommy_Hilfiger_Logo.jpg",
    },
    {
      storeName: "House of Fett Ardee Mall",
      description: "Women",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000139146.jpg",       
      brandLogo: "https://zulushop.in/uploads/seller/House_of_Fett_Logo.png",
    },
    {
      storeName: "Rare Rabbit Ambience Mall",
      description: "Men, Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2025/1000184868.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Rabbit_Logo.png",
    },
    {
      storeName: "Nike MGF Metropolitan Mall",
      description: "Men,Women, Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000128340.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/Nike_Logo.png",
    },
    {
      storeName: "Purple United Kids Airia Mall",
      description: "Kids",
      imageUrl: "https://zulushop.in/uploads/media/2024/1000137636.jpg",
      brandLogo: "https://zulushop.in/uploads/seller/PURPLE_UNITED_KIDS_Logo.png",
    },
    
  ];

  const handleBrandClick = () => {
    setShowDownloadPopup(true);
  };

  return (
    <div className="bg-black py-12 px-4 sm:px-6 lg:px-8">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto text-center mb-10">
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
          Brands Near You
        </h2>
        <p className="text-lg md:text-xl text-gray-300 font-light">
          Discover fashion showrooms near you
        </p>
      </div>

      {/* Grid Container */}
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8">
          {brandItems.map((item, index) => (
            <div
              key={index}
              onClick={handleBrandClick}
              className="bg-white/5 rounded-lg overflow-hidden 
                       transition-transform duration-300 hover:scale-105 
                       border border-white/10 cursor-pointer"
            >
              {/* Image Container */}
              <div className="aspect-[3/2] relative">
                <img
                  src={item.imageUrl}
                  alt={item.storeName}
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Content Container */}
              <div className="p-3">
                <div className="flex items-center justify-between">
                  <div className="flex-1">
                    <h3 className="text-white font-medium text-base truncate">
                      {item.storeName}
                    </h3>
                    <p className="text-gray-400 text-xs mt-0.5">
                      {item.description}
                    </p>
                  </div>
                  {item.brandLogo && (
                    <img
                      src={item.brandLogo}
                      alt={`${item.storeName} logo`}
                      className="w-8 h-8 object-contain rounded-md ml-3"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Download App Popup */}
      <DownloadAppPopup 
        isOpen={showDownloadPopup} 
        onClose={() => setShowDownloadPopup(false)} 
      />
    </div>
  );
};

export default BrandsGrid;
