import React from 'react';

const DownloadAppPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-black/80 backdrop-blur-xl border-2 border-red-900/30 rounded-xl p-8 max-w-sm w-full mx-4
                    transform transition-all duration-500 hover:scale-[1.02]
                    hover:shadow-[0_0_50px_rgba(255,0,0,0.2)]">
        <div className="text-center space-y-4">
          <h3 className="text-2xl font-bold text-white/90">Download App</h3>
          <p className="text-sm text-white/80 text-5xl md:text-l">
            To continue shopping,
          </p>
          <p className="text-sm text-white/80 text-5xl md:text-l translate-y-[-70%]">
            download the Zulu Club App
          </p>
          
          <div className="flex flex-col gap-3 mt-">
            <a 
              href="https://play.google.com/store/apps/details?id=com.zulu.consumer.zulu_consumer"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 
                       bg-white/10 backdrop-blur-sm border-2 border-red-900/20 rounded-lg px-6 py-3
                       hover:bg-white/20 hover:border-red-800/40
                       hover:shadow-[0_4px_16px_rgba(255,0,0,0.25)]
                       transition-all duration-300"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
              </svg>
              <span className="text-white/90">Get it on Google Play</span>
            </a>
            
            <a 
              href="https://apps.apple.com/in/app/zulu-club/id6739531325"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 
                       bg-white/10 backdrop-blur-sm border-2 border-red-900/20 rounded-lg px-6 py-3
                       hover:bg-white/20 hover:border-red-800/40
                       hover:shadow-[0_4px_16px_rgba(255,0,0,0.25)]
                       transition-all duration-300"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11"/>
              </svg>
              <span className="text-white/90">Download on App Store</span>
            </a>
          </div>

          <button
            onClick={onClose}
            className="mt-6 text-sm text-white/70 hover:text-white transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPopup; 