class ConversationalAgent {
    constructor() {
      this.CHAT_API = 'https://api.zulu.club/chat';
      this.FILTER_API = 'https://api.zulu.club/filter-products';
      this.RECOMMENDATIONS_API = 'https://api.zulu.club/get-recommendations';
    }
  
    async handleChat(conversationHistory = [], imageUrl = null) {
      try {
        const chatRequest = {
          conversation_history: conversationHistory.map(msg => ({
            text: msg.text,
            sender: msg.sender
          })),
          current_filter_context: {
            category: null,
            gender: null,
            brands: [],
            attributes: {
              price: {
                min: null,
                max: null
              },
              mall: null,
              style: null,
              color: null,
              occasion: null,
              fit: null,
              material: null
            },
            usage: {
              occasions: [],
              activities: [],
              seasons: [],
              weather: []
            },
            tags: [],
            searchKeywords: [],
            contextual_notes: {
              style_context: null,
              occasion_context: null,
              user_preferences: null,
              implicit_needs: null
            }
          }
        };

        // Add image_url if provided
        if (imageUrl) {
          chatRequest.image_url = imageUrl;
        }

        console.log('Sending chat request:', chatRequest);
  
        const response = await fetch(this.CHAT_API, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(chatRequest)
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        if (data.success) {
          return {
            reply: data.response,
            context: {
              // Main categories
              category: data.context.category || [],
              sub_category: data.context.sub_category || [],
              sub_sub_category: data.context.sub_sub_category || [],
              gender: data.context.gender || null,
              
              // Brand information
              brands: data.context.brands || [],
              
              // Product attributes
              attributes: {
                color: data.context.attributes?.color || null,
                fit: data.context.attributes?.fit || null,
                mall: data.context.attributes?.mall || null,
                material: data.context.attributes?.material || null,
                occasion: data.context.attributes?.occasion || null,
                price: {
                  min: data.context.attributes?.price?.min || null,
                  max: data.context.attributes?.price?.max || null
                },
                style: data.context.attributes?.style || null
              },
              
              // Usage context
              usage: {
                occasions: data.context.usage?.occasions || [],
                activities: data.context.usage?.activities || [],
                seasons: data.context.usage?.seasons || [],
                weather: data.context.usage?.weather || []
              },
              
              // Search metadata
              tags: data.context.tags || [],
              searchKeywords: data.context.searchKeywords || [],
              
              // Additional context
              contextual_notes: {
                style_context: data.context.contextual_notes?.style_context || null,
                occasion_context: data.context.contextual_notes?.occasion_context || null,
                user_preferences: data.context.contextual_notes?.user_preferences || null,
                implicit_needs: data.context.contextual_notes?.implicit_needs || null
              },
              
              // Flow control
              next_attribute_to_ask: data.context.next_attribute_to_ask || null,
              send_for_filtering: data.context.send_for_filtering || false
            }
          };
        } else {
          throw new Error('Chat API returned unsuccessful response');
        }
  
      } catch (error) {
        console.error('Chat API Error:', error);
        return {
          reply: "I'm having trouble understanding right now. Could you please try again?",
          context: {
            error: true,
            message: error.message
          }
        };
      }
    }
  
    async filterProducts(context) {
      try {
        const filterRequest = {
          attributes: {
            color: context.attributes?.color || null,
            fit: context.attributes?.fit || null,
            mall: context.attributes?.mall || null,
            material: context.attributes?.material || null,
            occasion: context.attributes?.occasion || null,
            price: {
              min: context.attributes?.price?.min || null,
              max: context.attributes?.price?.max || null
            },
            style: context.attributes?.style || null
          },
          brands: context.brands || [],
          category: context.category || [],
          sub_category: context.sub_category || [],
          sub_sub_category: context.sub_sub_category || [],
          tags: context.tags || [],
          searchkeywords: context.searchKeywords || [],
          usage: {
            occasions: context.usage?.occasions || [],
            activities: context.usage?.activities || [],
            seasons: context.usage?.seasons || [],
            weather: context.usage?.weather || []
          }
        };
  
        console.log('Filtering products with:', filterRequest);
  
        const response = await fetch(this.FILTER_API, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(filterRequest)
        });
  
        if (!response.ok) {
          throw new Error(`Filter API error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        if (data.success) {
          return {
            success: true,
            products: data.products.map(product => ({
              id: product.id,
              basic_info: {
                product_details: {
                  name: product.zulu_data.name,
                  link: product.zulu_data.product_url,
                  image_url: product.zulu_data.image_url,
                  mall_name: product.zulu_data.mall,
                  product_url: product.zulu_data.product_url
                },
                brand: {
                  detected: product.zulu_data.brand
                },
                price: {
                  mrp: product.zulu_data.price.mrp,
                  discounted_price: product.zulu_data.price.discounted_price
                },
                classification: {
                  category: {
                    main: product.zulu_data.category,
                    sub: product.zulu_data.sub_category,
                    sub_sub: product.zulu_data.sub_sub_category
                  }
                }
                
              }
            })),
            total_matches: data.total_matches,
            csv_path: data.csv_path,
            filter_context: data.filter_context
          };
        } else {
          throw new Error('Filter API returned unsuccessful response');
        }
  
      } catch (error) {
        console.error('Filter API Error:', error);
        return {
          success: false,
          error: error.message,
          products: []
        };
      }
    }
  
    async getRecommendations(context, csvPath, conversationHistory = []) {
      try {
        // Validate inputs
        if (!csvPath) {
          console.warn('Missing CSV path for recommendations');
          return this.getDefaultResponse();
        }

        // Ensure we have filtered products before proceeding
        if (!context.filteredProducts || context.filteredProducts.length === 0) {
          console.warn('No filtered products available for recommendations');
          return this.getDefaultResponse();
        }
  
        // Log the full context for debugging
        console.log('Starting recommendations fetch:', {
          csvPath,
          productsCount: context.filteredProducts?.length,
          timestamp: new Date().toISOString()
        });
  
        const recommendRequest = {
          csv_path: csvPath.trim(),
          conversation_history: conversationHistory.map(msg => ({
            text: msg.text,
            sender: msg.sender
          })),
          // Add product data to ensure we have content to process
          products: context.filteredProducts.map(product => ({
            id: product.id,
            name: product.basic_info?.product_details?.name || '',
            price: product.basic_info?.price?.discounted_price || product.basic_info?.price?.mrp || '',
            category: product.basic_info?.classification?.category?.main || '',
            sub_category: product.basic_info?.classification?.category?.sub || '',
            brand: product.basic_info?.brand?.detected || ''
          }))
        };
  
        console.log('Sending recommendations request with product count:', recommendRequest.products.length);
  
        const response = await fetch(this.RECOMMENDATIONS_API, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(recommendRequest)
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          console.error('API Error Response:', errorText);
          throw new Error(`API returned ${response.status}: ${errorText}`);
        }
  
        const data = await response.json();
  
        if (data.success && data.recommendations) {
          console.log('Raw recommendations data:', data.recommendations);

          // Map recommended product IDs to full product objects
          const recommendedProducts = data.recommendations.recommended_products
            .map(productId => {
              // Find the matching product from filtered products
              const product = context.filteredProducts.find(p => String(p.id) === String(productId));
              if (!product) return null;

              // Log the found product for debugging
              console.log('Found matching product:', product);

              return {
                id: productId,
                zulu_data: {
                  name: product.basic_info.product_details.name,
                  brand: product.basic_info.brand.detected,
                  image_url: product.basic_info.product_details.image_url,
                  product_url: product.basic_info.product_details.link,
                  mall: product.basic_info.product_details.mall_name,
                  price: {
                    mrp: product.basic_info.price.mrp,
                    discounted_price: product.basic_info.price.discounted_price
                  },
                  category: product.basic_info.classification.category.main,
                  sub_category: product.basic_info.classification.category.sub
                }
              };
            })
            .filter(Boolean); // Remove any null values

          console.log('Mapped recommended products:', recommendedProducts);

          return {
            success: true,
            recommended_products: recommendedProducts,
            explanations: data.recommendations.explanations || {},
            style_suggestions: data.recommendations.style_suggestions || [],
            total_matches: recommendedProducts.length
          };
        }
  
        return this.getDefaultResponse();
  
      } catch (error) {
        console.error('Recommendations API Error:', {
          message: error.message,
          type: error.name,
          timestamp: new Date().toISOString()
        });
        return this.getDefaultResponse();
      }
    }
  
    // Helper method for consistent default response
    getDefaultResponse() {
      return {
        success: false,
        recommended_products: [],
        explanations: {},
        style_suggestions: [],
        total_matches: 0
      };
    }
  
    // Update handleQuery to include recommendations
    async handleQuery(query, conversationHistory = []) {
      try {
        const chatResponse = await this.handleChat([...conversationHistory, { 
          sender: 'user', 
          text: query 
        }]);
  
        if (chatResponse.context.send_for_filtering) {
          // Get filtered products first
          const filterResponse = await this.filterProducts(chatResponse.context);
          
          console.log('Filter Response:', {
            success: filterResponse.success,
            productsCount: filterResponse.products?.length,
            csvPath: filterResponse.csv_path
          });

          // Only proceed if we have filtered products
          if (filterResponse.success && filterResponse.products?.length > 0) {
            // Create context with filtered products
            const contextWithProducts = {
              ...chatResponse.context,
              filteredProducts: filterResponse.products // Add filtered products to context
            };

            console.log('Getting recommendations with filtered products:', filterResponse.products.length);

            // Then get recommendations using the updated context
            const recommendResponse = await this.getRecommendations(
              contextWithProducts,
              filterResponse.csv_path,
              [...conversationHistory, { sender: 'user', text: query }]
            );
            
            return {
              ...chatResponse,
              filteredProducts: filterResponse.products,
              totalMatches: filterResponse.total_matches,
              csvPath: filterResponse.csv_path,
              recommendations: {
                products: recommendResponse.recommended_products || [],
                explanations: recommendResponse.explanations || {},
                style_suggestions: recommendResponse.style_suggestions || [],
                totalMatches: recommendResponse.total_matches || 0
              }
            };
          } else {
            console.warn('No filtered products found');
            return {
              ...chatResponse,
              filteredProducts: [],
              totalMatches: 0,
              recommendations: {
                products: [],
                explanations: {},
                style_suggestions: [],
                totalMatches: 0
              }
            };
          }
        }
  
        return chatResponse;
      } catch (error) {
        console.error('Query handling error:', error);
        return {
          reply: "I'm having trouble processing your request. Please try again.",
          context: {
            error: true,
            message: error.message
          }
        };
      }
    }
  }
  
  export default ConversationalAgent;