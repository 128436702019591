import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ambientMall from '../assets/Ambience-1.jpg';
import airiaMall from '../assets/Airia-1.jpg';
import vegasMall from '../assets/Ardee-1.jpg';
import DownloadAppPopup from './DownloadAppPopup';
// import sectionMall from '../assets/Worldmark.jpg';

const RunMall = () => {
  const navigate = useNavigate();
  const [currentMall, setCurrentMall] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const malls = [
    {
      id: 1,
      name: "Ambience Mall",
      location: "Gurugram",
      image: ambientMall,
      outlets: "60+ Outlets",
    },
    {
      id: 2,
      name: "Airia Mall",
      location: "Gurugram",
      image: airiaMall,
      outlets: "40+ Outlets",
    },
    {
      id: 3,
      name: "Ardee Mall",
      location: "Gurugram",
      image: vegasMall,
      outlets: "50+ Outlets",
    }
  ];

  // Auto-rotate malls
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMall((prev) => (prev + 1) % malls.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextMall = () => {
    setCurrentMall((prev) => (prev + 1) % malls.length);
  };

  const prevMall = () => {
    setCurrentMall((prev) => (prev - 1 + malls.length) % malls.length);
  };

  // Mobile Layout
  const MobileLayout = () => (
    <div className="bg-black py-8 px-4">
      <div className="max-w-7xl mx-auto">
        {/* Mobile Text Content */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-white mb-4">
            Explore Malls & Markets
          </h2>
          
          <p className="text-xl text-gray-300 mb-6 leading-relaxed">
            Shop the latest collection from top shopping destinations in Delhi NCR
          </p>

          <button 
            className="bg-white/10 backdrop-blur-sm hover:bg-white/20 
                       text-white font-semibold py-2.5 px-6
                       rounded-lg border border-white/20
                       transition-all duration-300 ease-in-out"
            onClick={() => setIsOpen(true)}
          >
            Get Started
          </button>
        </div>

        {/* Mobile Mall Card */}
        <div className="relative aspect-[3/4] max-w-lg mx-auto w-[85%]">
          {/* Navigation Buttons */}
          <button
            onClick={prevMall}
            className="absolute left-2 top-1/2 -translate-y-1/2 z-20
                     bg-black/50 hover:bg-black/70 
                     rounded-full p-1.5
                     text-white/90 hover:text-white
                     transition-all duration-300"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          
          <button
            onClick={nextMall}
            className="absolute right-2 top-1/2 -translate-y-1/2 z-20
                     bg-black/50 hover:bg-black/70 
                     rounded-full p-1.5
                     text-white/90 hover:text-white
                     transition-all duration-300"
          >
            <ChevronRight className="w-5 h-5" />
          </button>

          {/* Mall Image */}
          <div className="relative w-full h-full rounded-xl overflow-hidden 
                        bg-white/5 backdrop-blur-sm border border-white/10">
            <img
              src={malls[currentMall].image}
              alt={malls[currentMall].name}
              className="w-full h-full object-cover transition-opacity duration-500"
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
              <div className="absolute bottom-4 left-4 right-4">
                <h3 className="text-xl font-bold text-white mb-2">
                  {malls[currentMall].name}
                </h3>
                <div className="flex justify-between items-center">
                  <p className="text-sm text-gray-300">{malls[currentMall].location}</p>
                  <span className="bg-white/10 backdrop-blur-sm px-2 py-1 
                                 rounded-full text-xs text-white">
                    {malls[currentMall].outlets}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Mall Indicators */}
          <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 
                        flex space-x-1.5">
            {malls.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentMall(index)}
                className={`w-1.5 h-1.5 rounded-full transition-all duration-300 
                          ${currentMall === index ? 'bg-white w-3' : 'bg-white/30'}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  // Return either mobile or desktop layout
  return (
    <>
      {isMobile ? (
        <MobileLayout />
      ) : (
        <div className="bg-black py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center gap-12">
              {/* Left side - Text Content */}
              <div className="w-full lg:w-1/2 text-left">
                <h2 className="text-4xl md:text-4xl font-bold text-white mb-6 translate-x-[130px] translate-y-[20px]">
                  Explore Malls & Markets
                </h2>
                
                {/* Main Description */}
                <p className="text-2xl text-gray-300 mb-8 leading-relaxed translate-x-[125px] translate-y-[20px]">
                  Shop the latest collection from top shopping destinations in Delhi NCR
                </p>

                {/* Get Started Button */}
                <div className="translate-x-[125px] translate-y-[20px] mb-12">
                  <button 
                    className="bg-white/10 backdrop-blur-sm hover:bg-white/20 
                               text-white font-semibold py-3 px-8
                               rounded-lg border border-white/20
                               transition-all duration-300 ease-in-out
                               transform hover:scale-105"
                    onClick={() => setIsOpen(true)}
                  >
                    Get Started
                  </button>
                </div>

                {/* Existing subsections */}
                <div className="mb-8 translate-x-[100px]">
                  {/* ... rest of your component ... */}
                </div>
              </div>

              {/* Right side - Mall Card */}
              <div className="w-full lg:w-1/2">
                <div className="relative aspect-[3/4] max-w-lg mx-auto w-[350px] h-[400px] translate-x-[30px]">
                  {/* Navigation Buttons */}
                  <button
                    onClick={prevMall}
                    className="absolute left-2 top-1/2 -translate-y-1/2 z-20
                             bg-black/50 hover:bg-black/70 
                             rounded-lg p-2
                             text-white/90 hover:text-white
                             transition-all duration-300"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  
                  <button
                    onClick={nextMall}
                    className="absolute right-2 top-1/2 -translate-y-1/2 z-20
                             bg-black/50 hover:bg-black/70 
                             rounded-full p-2
                             text-white/90 hover:text-white
                             transition-all duration-300"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>

                  {/* Mall Image */}
                  <div className="relative w-full h-full rounded-2xl overflow-hidden 
                                bg-white/5 backdrop-blur-sm border border-white/10">
                    <img
                      src={malls[currentMall].image}
                      alt={malls[currentMall].name}
                      className="w-full h-full object-cover transition-opacity duration-500"
                    />
                    {/* Overlay */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent">
                      <div className="absolute bottom-6 left-6 right-6">
                        <h3 className="text-2xl font-bold text-white mb-2">
                          {malls[currentMall].name}
                        </h3>
                        <div className="flex justify-between items-center">
                          <p className="text-gray-300">{malls[currentMall].location}</p>
                          <span className="bg-white/10 backdrop-blur-sm px-3 py-1 rounded-full text-sm text-white">
                            {malls[currentMall].outlets}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Mall Indicators */}
                  <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 
                                flex space-x-2">
                    {malls.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentMall(index)}
                        className={`w-2 h-2 rounded-full transition-all duration-300 
                                  ${currentMall === index ? 'bg-white w-4' : 'bg-white/30'}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DownloadAppPopup isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default RunMall; 