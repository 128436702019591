import React, { useRef, useEffect } from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import RunMall from '../components/RunMall';
import RunVideoTour from '../components/RunVideoTour';
import RunAskZulu from '../components/RunAskZulu';
import GetStartedSection from '../components/GetStartedSection';
import MarketsGrid from '../components/MarketsGrid';
import BrandsGrid from '../components/BrandsGrid';
import Footer from '../components/Footer';
import ExperienceZulu from '../components/ExperienceZulu';
import FAQ from '../components/FAQ';
import LatestCollections from '../components/LatestCollections';
import FeaturedMalls from '../components/FeaturedMalls';
import TeamSection from '../components/TeamSection';
import ChatInterface from '../components/ChatInterface';
import VideoSection from '../components/VideoSection';
import ReactGA from 'react-ga4';

const ConsumerPage = () => {
  const heroRef = useRef(null);
  const marketsRef = useRef(null);
  const brandsRef = useRef(null);
  const latestCollectionsRef = useRef(null);
  const experienceRef = useRef(null);

  const scrollToHero = () => heroRef.current?.scrollIntoView({ behavior: 'smooth' });
  const scrollToMarkets = () => {
    if (marketsRef.current) {
      const yOffset = -80; // Adjust for header height
      const element = marketsRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };
  const scrollToBrands = () => brandsRef.current?.scrollIntoView({ behavior: 'smooth' });
  const scrollToExperience = () => experienceRef.current?.scrollIntoView({ behavior: 'smooth' });
  
  useEffect(() => {
    // Initialize GA4
    ReactGA.initialize('G-BXQZ9G9TGL');
    
    // Send pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }, []);

  return (
    <div className='bg-black'>
      <div className="bg-black">
        <div className='h-[100vh]'>
          <div className="relative bg-[url('./assets/bg-opt.png')] bg-black bg-cover bg-no-repeat bg-center w-full h-[100%] mx-auto">
            <div className="absolute inset-0 bg-black/60 z-10"></div>
            <div className="relative z-20 flex flex-col items-center justify-center h-full">
              <Navbar />
              <HeroSection ref={heroRef} />
            </div>
          </div>
        </div>

        <VideoSection />
        <RunMall />
        <RunVideoTour />
        <RunAskZulu />

        <div className='mt-6'>
          {/* <GetStartedSection
            onCardClick={[scrollToMarkets, scrollToBrands, scrollToExperience]}
          /> */}
        </div>
        <div className='mt-10'>
          {/* <div ref={marketsRef}>
            <FeaturedMalls />
          </div> */}
        </div>
        {/* <div ref={marketsRef} className='mt-10'>
          <MarketsGrid scrollToHero={scrollToHero} scrollToBrands={scrollToBrands} />
        </div> */}

        <div ref={brandsRef} className='md:mt-6'>
          <BrandsGrid />
        </div>

        

        {/* <div ref={latestCollectionsRef} className='md:mt-10'>
          <LatestCollections />
        </div> */}

        <div ref={experienceRef} className='mt-10'>
          <ExperienceZulu />
        </div>

        <div className='md:mt-[-150px]'>
          <FAQ />
        </div>

        {/* <div className='mt-10'>
          <TeamSection />
        </div> */}

        {/* <ChatInterface /> */}

        <Footer />
      </div>
    </div>
  );
};

export default ConsumerPage;
