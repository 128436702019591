import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'lucide-react';
import DownloadAppPopup from './DownloadAppPopup';


const Navbar = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState('Consumers');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showDownloadPopup, setShowDownloadPopup] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-black/90 backdrop-blur-md py-4' : 'bg-transparent py-6'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          {/* Logo - Shifted left */}
          <Link to="/" className="flex-shrink-0 -ml-[%]">
            <h1 className="text-2xl font-bold text-white hover:text-gray-200 transition-colors duration-200">
              ZULU
            </h1>
          </Link>

          {/* Desktop Navigation - Shifted left */}
          <div className="hidden md:flex items-center space-x-4 -ml-[10%]">
            <Link
              to="/"
              className={`px-6 py-3 rounded-lg text-base font-medium transition-all duration-200 min-w-[120px] text-center
                ${activePage === 'Consumers'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => setActivePage('Consumers')}
            >
              HOME
            </Link>
            {/* <button
              className={`px-6 py-3 rounded-lg text-base font-medium transition-all duration-200 min-w-[120px]
                ${activePage === 'AboutUs'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('AboutUs');
                navigate('/about-us');
              }}
            >
              ABOUT US
            </button> */}
            <button
              className={`px-6 py-3 rounded-lg text-base font-medium transition-all duration-200 min-w-[120px]
                ${activePage === 'AskZulu'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('AskZulu');
                navigate('/ask-zulu');
              }}
            >
              ASK ZULU
            </button>
            <button
              className={`px-6 py-3 rounded-lg text-base font-medium transition-all duration-200 min-w-[120px]
                ${activePage === 'Brands'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('Brands');
                navigate('/brands');
              }}
            >
              BRANDS
            </button>
          </div>

          {/* Mobile Menu Button - Shifted left */}
          <button
            onClick={toggleMenu}
            className="md:hidden p-2 rounded-lg text-gray-300 hover:text-white hover:bg-white/5 
                     transition-all duration-200 -ml-[10%]"
          >
            <Menu size={24} />
          </button>
        </div>
      </div>

      {/* Mobile Menu - Shifted right */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-black/90 backdrop-blur-md border-t border-white/5 z-[60]">
          <div className="px-4 py-6 space-y-4 ml-[5%]">
            <Link
              to="/"
              className={`block px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${activePage === 'Consumers'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('Consumers');
                setIsMenuOpen(false);
              }}
            >
              HOME
            </Link>
            <button
              className={`w-full text-left px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${activePage === 'AskZulu'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('AskZulu');
                setIsMenuOpen(false);
                navigate('/ask-zulu');
              }}
            >
              ASK ZULU
            </button>
            <button
              className={`w-full text-left px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                ${activePage === 'Brands'
                  ? 'text-white'
                  : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              onClick={() => {
                setActivePage('Brands');
                setIsMenuOpen(false);
                navigate('/brands');
              }}
            >
              BRANDS
            </button>
          </div>
        </div>
      )}

      <DownloadAppPopup 
        isOpen={showDownloadPopup} 
        onClose={() => setShowDownloadPopup(false)} 
      />
    </nav>
  );
};

export default Navbar;
