import React from 'react';
import { Send, Youtube, Instagram, Facebook, Linkedin } from 'lucide-react';
import playstoreLogo from "../assets/playstore.png";
import appstoreLogo from "../assets/app-store.png";
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="py-6 md:py-12 px-4 sm:px-6">
        <div className="w-[80%] mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
            See it, Love it, Shop it
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 items-start">
            {/* First Column - ZULU Club */}
            <div className="space-y-6 flex flex-col items-center">
              <h3 className="text-white-500 text-xl font-semibold">ZULU Club</h3>
              <div className="flex flex-col gap-4">
                <button 
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=com.zulu.consumer.zulu_consumer', '_blank')}
                  className="bg-black text-white px-6 py-4 rounded-lg font-semibold border border-gray-700 hover:bg-gray-900 transition-colors flex items-center gap-4"
                >
                  <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center flex-shrink-0">
                    <svg className="w-7 h-7 text-black" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M3,20.5V3.5C3,2.91 3.34,2.39 3.84,2.15L13.69,12L3.84,21.85C3.34,21.6 3,21.09 3,20.5M16.81,15.12L6.05,21.34L14.54,12.85L16.81,15.12M20.16,10.81C20.5,11.08 20.75,11.5 20.75,12C20.75,12.5 20.5,12.92 20.16,13.19L17.89,14.5L15.39,12L17.89,9.5L20.16,10.81M6.05,2.66L16.81,8.88L14.54,11.15L6.05,2.66Z"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <div className="text-xs text-gray-400">Get it on</div>
                    <div className="text-sm font-semibold">Google Play</div>
                  </div>
                </button>

                <button 
                  onClick={() => window.open('https://apps.apple.com/in/app/zulu-club/id6739531325', '_blank')}
                  className="bg-black text-white px-6 py-4 rounded-lg font-semibold border border-gray-700 hover:bg-gray-900 transition-colors flex items-center gap-4"
                >
                  <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center flex-shrink-0">
                    <svg className="w-7 h-7 text-black" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11"/>
                    </svg>
                  </div>
                  <div className="text-left">
                    <div className="text-xs text-gray-400">Get it on the</div>
                    <div className="text-sm font-semibold">App Store</div>
                  </div>
                </button>
              </div>
            </div>

            {/* Second Column - Navigation */}
            <div className="space-y-4 flex flex-col items-center">
              <h3 className="text-white-500 text-xl font-semibold">Navigation</h3>
              <ul className="space-y-3 text-center">
                {[
                  'Brands',
                  'Privacy Policy',
                  'Terms & Conditions',
                  'Return Policy',
                  'Cancellation and Refund',
                  'Shipping and Delivery',
                  
                ].map((item) => (
                  <li
                    key={item}
                    className="text-gray-400 hover:text-white cursor-pointer"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            {/* Third Column - Contact */}
            <div className="space-y-4 flex flex-col items-center">
              <h3 className="text-white-500 text-xl font-semibold">Contact</h3>
              <div className="space-y-3 text-gray-400 text-center">
                <p>hi@zulu.club</p>
                <p>+91 85279 20080</p>
                <p>Golf Course Extn Road,</p>
                <p>Gurgaon,</p>
                <p>Haryana, India</p>
                <a 
                  href="https://www.instagram.com/zuluclubindia?igsh=YWg4ZXFnenFzMWM4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 justify-center hover:text-white transition-colors"
                >
                  <Instagram size={20} />
                  <span>Follow us on Instagram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 text-center mt-8 pt-4 border-t border-gray-800">
        <p className="text-gray-400 text-sm">
          A MADMIND TECH INNOVATIONS PRIVATE LIMITED COMPANY
        </p>
      </div>
    </footer>
  );
};

export default Footer;
